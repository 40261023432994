import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const TextCustom = makeShortcode("TextCustom");
const Paper = makeShortcode("Paper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mental-healthwhat-is-that-exactly"
    }}>{`Mental health...what is that exactly?`}</h1>
    <p>{`Look at the piece of rope below. Now imagine that this rope represents mental health (go with me on this).`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m10/46.svg" alt="Rope" mdxType="SingleImage" />
    <TextCustom customColor="green" paragraph mdxType="TextCustom">
  At one end (on the far right) is good mental health. Here, you feel in control of your emotions and have good
  functioning in all areas of your life – in your social relationships, at work or in studies, at home, and doing the
  things you enjoy.
    </TextCustom>
    <TextCustom customColor="orange" paragraph mdxType="TextCustom">
  Further along the rope is poor mental health or what we might call “mental health problems”. Here, you feel unable to
  cope with the normal stresses of life and function poorly in some areas. Perhaps social relationships are getting more
  difficult, or performance at school or work is declining.
    </TextCustom>
    <TextCustom customColor="maroon" paragraph mdxType="TextCustom">
  At the fuzzy, frayed end of the rope (on the far left) are ‘mental illnesses’ – diagnosable conditions such as
  depression and clinical anxiety, which interfere with most aspects of daily life.
    </TextCustom>
    <Paper mdxType="Paper">
  <p>
    What’s important to remember is that an individual will vary in their position along this rope at different points
    in their life. It’s also important to realise that an individual may experience some symptoms of a mental illness
    but not to the extent that they would be diagnosed with a mental illness. The term ‘mental health problem’ covers
    both mental illnesses and symptoms of mental illnesses.
  </p>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      